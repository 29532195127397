body {
  font-family: 'Roboto';
  overflow-x: hidden;
  position: relative;
}
html {
  overflow-x: hidden
}
.phone-input-field:focus {
  border: 2px solid #33cc70;
  outline: none;
  -webkit-transition: 1s;
  transition: 1s;
}